import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open Workout 21.2`}</strong></p>
    <p>{`For time:`}</p>
    <p>{`10 dumbbell snatches\\
15 burpee box jump-overs\\
20 dumbbell snatches\\
15 burpee box jump-overs\\
30 dumbbell snatches\\
15 burpee box jump-overs\\
40 dumbbell snatches\\
15 burpee box jump-overs\\
50 dumbbell snatches\\
15 burpee box jump-overs`}</p>
    <p><em parentName="p">{`50-lb. dumbbell, 24-in. box`}</em></p>
    <p>{`Time cap: 20 min.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      